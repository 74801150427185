import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { base58_to_binary } from 'base58-js'

import useUserLogin from '../hooks/auth/user-login';
import { isSessionValid, } from '../utils/helperFunctions';
import useUserConfig from '../hooks/auth/user-config';
import { errorToast } from '../utils/toastMessage';

const LoadingSpinner = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 z-50">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
    </div>
  );
};

const Login = () => {

  // init
  const navigate = useNavigate()
  const currentUrl = window.location.href;
  // const initData = "user=%7B%22id%22%3A5541937329%2C%22first_name%22%3A%22lan%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22muzhuiming%22%2C%22language_code%22%3A%22en%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&chat_instance=-2397476229769709411&chat_type=sender&auth_date=1730365777&hash=e7aa173ea537c72ba6dd5497afda38c69cad01741d31ee1ae110ef62a28b01d9"

  // api
  const useUserLoginMutation = useUserLogin()
  const useUserConfigMutation = useUserConfig()

  function getOrderIdByParamsV2(tgWebAppStartParam = '') {
    if (tgWebAppStartParam === '') {
      return {}
    }
    let tmp = base58_to_binary(tgWebAppStartParam)
    // console.log(tmp)
    let str = String.fromCharCode.apply(null, tmp)
    // console.log(str)
    // let str = String.fromCharCode(tmp)
    tmp = str.split("&")
    let params = {}
    tmp.forEach(e => {
      let m = e.split('=')
      if (m.length === 2) {
        params[m[0]] = m[1]
      }
    })
    // console.log(str)
    return params
  }

  // Create a URL object
  const url = new URL(currentUrl);

  // Use URLSearchParams to get query parameters
  const params = new URLSearchParams(url.search);

  // Get a specific query parameter by name
  const paramValue = params.get('tgWebAppStartParam');
  const finalParam = getOrderIdByParamsV2(paramValue ?? '')
  console.log(finalParam?.inviter, 'Inviter');

  const handleLogin = useCallback(() => {
    const webApp = window?.Telegram?.WebApp;
    console.log(localStorage.getItem('token'), 'token', webApp?.platform);

    const payload = {
      platform: webApp?.platform,
      version: webApp?.version,
      ts: Date.now(),
      // init_data : initData,
      init_data: webApp?.initData,
      inviter: Number(finalParam?.inviter)
    };

    const payload_config = {
      platform: webApp?.platform,
      version: webApp?.version,
      ts: Date.now(),
      channel: 'tt',
      device_no: webApp?.BiometricManager.deviceId
    };

    if (!isSessionValid()) {
      useUserConfigMutation.mutate(payload_config, {
        onSuccess: (data) => {
          if (data?.data?.error === 0) {
            useUserLoginMutation.mutate(payload);
          } else {
            errorToast('Something went wrong while config');
          }
        }
      });
      console.log('Session expired or not set. User logged out.');
    } else {
      console.log('Session is valid.');
      navigate('/home');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, useUserConfigMutation, useUserLoginMutation]);

  useEffect(() => {
    handleLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="min-h-screen bg-[#ffffff] flex items-center justify-center">
      <LoadingSpinner />
    </div>
  );
};

export default Login;