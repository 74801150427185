import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useGetWalletInfo from '../hooks/wallet/get-wallet-info';
import useGetWalletWithdrawConfig from '../hooks/wallet/get-withdraw-config';
import useBindWalletAddress from '../hooks/wallet/bind-wallet-address';
import ModalLoader from '../components/ModalLoader';
import { errorToast, successToast } from '../utils/toastMessage';
import useWalletWithdrawAmount from '../hooks/wallet/wallet-wuthdraw-amount';


const Withdraw = () => {
  // init
  const navigate = useNavigate();
  const token = localStorage?.getItem('token')

  // state
  const [addressId, setAddressId] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedChangeAddress, setSelectedChangeAddress] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState({ name: 'TRC20', chainnet: 'trx', address: '' });
  const [error, setError] = useState('');
  const [networkError, setNetworkError] = useState('');

  // api
  const { data: walletInfoData, isLoading: walletDataIsLoading,refetch:walletDataRefetch } = useGetWalletInfo({ token: token })
  const { data: walletWithdrawConfigData, isLoading: walletWithdrawConfigIsLoading, isFetching } = useGetWalletWithdrawConfig({ token: token })
  const useBindWalletAddressMutation = useBindWalletAddress()
  const useWalletWithdrawAmountMutation = useWalletWithdrawAmount()

  useEffect(() => {
    if (walletWithdrawConfigData?.data && !walletWithdrawConfigIsLoading) {
      setSelectedNetwork({ name: walletWithdrawConfigData?.data?.data?.Networks[0]?.chain_nets?.name, chainnet: walletWithdrawConfigData?.data?.data?.Networks[0]?.chain_nets?.chain_net, address: walletWithdrawConfigData?.data?.data?.Networks[0]?.address })
    }
  }, [walletWithdrawConfigData?.data, walletWithdrawConfigIsLoading, isFetching])

  const toggleBottomSheet = () => setIsDropdownOpen(!isDropdownOpen);

  const handleNetworkSelect = (network) => {
    setSelectedNetwork(network);
    setIsDropdownOpen(false);
    setNetworkError('');
  };

  const handleAddressChange = (e) => {
    setAddressId(e.target.value);
    setError(''); // Clear error when user types
  };

  const validateAndSubmit = () => {
    if (!selectedNetwork || !selectedNetwork.chainnet) {
      setNetworkError('Please select a network');
      return;
    }
    setNetworkError('');

    if (!addressId.trim()) {
      setError('Please enter a valid address');
      return;
    }
    handleBindWalletAdress();
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      validateAndSubmit();
    }
  };

  const handleBindWalletAdress = () => {
    const webApp = window?.Telegram?.WebApp
    const token = localStorage.getItem('token')
    const payload = {
      platform: webApp?.platform,
      version: webApp?.version,
      ts: Date.now(),
      channel: 'tt',
      device_no: webApp?.BiometricManager.deviceId,
      token: token,
      network: selectedNetwork?.chainnet,
      address: addressId
    }
    useBindWalletAddressMutation?.mutate(payload, {
      onSuccess: (data) => {

        if (data?.data?.error === 0) {
          setSelectedNetwork({ ...selectedNetwork, address: addressId })
          successToast('Address added successfully')
        } else {
          errorToast('Something went wrong while Address Binding')
        }
      }
    })
  }

  const handleWithdrawAmount = (index_no) => {

    const webApp = window?.Telegram?.WebApp
    const token = localStorage.getItem('token')
    const payload = {
      platform: webApp?.platform,
      version: webApp?.version,
      ts: Date.now(),
      channel: 'tt',
      device_no: webApp?.BiometricManager.deviceId,
      token: token,
      index: index_no,
      network: selectedNetwork?.chainnet,
    }

    useWalletWithdrawAmountMutation?.mutate(payload, {
      onSuccess: (data) => {
        console.log(data?.data);
        if (data?.data?.error === 0) {
          successToast('Withdrawal request submitted successfully')
          walletDataRefetch()
        } else {
          errorToast('Something went wrong while withdraw')
        }
      }
    })
  }

  return (
    <div className="font-sans bg-white min-h-screen p-4 flex flex-col">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">

        <div onClick={() => navigate(-1)}>
          <svg className='w-4 h-4 mr-6' viewBox="0 0 10 19" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.67449 0.40287C10.1085 0.924524 10.1085 1.72512 9.67449 2.24677L2.97934 9.62238L9.67449 16.998C9.92837 17.5273 9.83945 18.177 9.45521 18.6003C9.07097 19.0236 8.48115 19.1216 8.00071 18.8419L0.368233 10.5996C0.138634 10.3363 0.00721185 9.98747 0 9.62238C0.00288841 9.26171 0.135282 8.91698 0.368236 8.66355L7.91702 0.40287C8.14953 0.145009 8.46583 0 8.79576 0C9.12568 0 9.44198 0.14501 9.67449 0.40287V0.40287Z" fill="black" />
          </svg>
        </div>

        <h1 className="text-lg font-inter font-normal">Withdraw</h1>
        <div className="w-6 h-6"></div>
      </div>

      {(walletDataIsLoading || walletWithdrawConfigIsLoading) ? (
         <div className="flex-grow flex items-center justify-center" style={{ minHeight: '100%' }}>
         <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
       </div>
      ) : (
        <>
          {/* Balance Card */}
          <div className="bg-black rounded-xl p-4 mb-6 flex items-center">
            <div className="bg-[#257059] rounded-full w-12 h-12 flex items-center justify-center mr-4">
              <svg className='fill-current w-5 h-5' viewBox="0 0 35 33" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M35 14.925C35 12.2232 28.392 10.6398 20.9709 10.2643V6.95732H31.3376V0H3.66178V6.95732H14.0291V10.2643C6.60866 10.6399 0 12.2239 0 14.925C0 17.6255 6.60802 19.2088 14.0291 19.5844V33H20.9715V19.5851C28.392 19.2095 35 17.6255 35 14.925L35 14.925ZM20.9709 18.4274V18.4133C19.8369 18.4827 18.6567 18.5059 17.4997 18.5059C16.3426 18.5059 15.1625 18.4827 14.0285 18.4133V18.4274C5.97463 18.0197 1.15642 16.2447 1.15642 14.925C1.15642 13.6059 5.97398 11.8296 14.0285 11.4219V17.2537C15.1394 17.3232 16.2964 17.3463 17.4997 17.3463C18.7261 17.3463 19.8831 17.3232 20.9709 17.2537V11.4219C29.0247 11.8296 33.8429 13.6059 33.8429 14.925C33.8429 16.2447 29.0254 18.0197 20.9709 18.4274V18.4274Z" fill="white" />
              </svg>
            </div>
            <div className="text-white">
              <span className="text-2xl font-inter font-bold">{walletInfoData?.data?.data?.token_list[0]?.balance ?? 'N/A'} </span>
              <span className="text-lg font-inter font-bold">{walletInfoData?.data?.data?.token_list[0]?.symbol?.toUpperCase() ?? 'N/A'}</span>
            </div>
          </div>

          {/* Withdraw Form */}
          <div className="mb-6">
            {/* <h2 className="text-lg font-inter font-bold mb-2">Withdraw to</h2> */}
            <div className="flex justify-between items-center mb-2">
              <h2 className="text-lg font-semibold">Withdraw to</h2>
              <div onClick={() => navigate('/withdarwalrecords')} className="text-lg font-semibold">Withdrawal Record &gt;</div>
            </div>

            {!!selectedNetwork?.address && (<div className="bg-white py-4 rounded-lg mb-1">
              <p className="text-gray-500 break-all line-clamp-2">
                Address: {selectedNetwork?.address ?? addressId}
              </p>
              <div onClick={() => setSelectedChangeAddress(!selectedChangeAddress)} className="flex justify-end">
                <div className="text-[#0F6FD1] underline hover:underline">
                  Change Withdrawal Address
                </div>
              </div>
            </div>)}
            {(!(!!selectedNetwork?.address) || selectedChangeAddress) && (<div className="mb-6">
              <div className="relative">
                <div className="flex items-center bg-gray-100 rounded-lg p-3 mb-1 px-6">
                  <input
                    type="text"
                    placeholder="Please enter the address"
                    className="flex-grow font-medium font-inter bg-transparent outline-none"
                    value={addressId}
                    onChange={handleAddressChange}
                    onKeyPress={handleKeyPress}
                  />
                  <button
                    onClick={validateAndSubmit}
                    className="text-xl font-inter font-bold"
                  >
                    OK
                  </button>
                </div>
                {error && (
                  <p className="text-red-500 text-sm mt-1 ml-2">{error}</p>
                )}
              </div>
            </div>)}

            <h2 className="text-lg font-inter font-bold mb-2">Choose network</h2>
            <div className="relative mb-2">
              <div
                className="bg-gray-100 rounded-lg p-3 flex justify-between items-center cursor-pointer"
                onClick={toggleBottomSheet}
              >
                <span>{selectedNetwork?.name ?? 'Select a network'}</span>
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </div>
            </div>
            {networkError && (
              <p className="text-red-500 text-sm mb-4">{networkError}</p>
            )}
          </div>

          {/* Select Withdrawal */}
          <div className="flex-grow overflow-y-auto">
            <h2 className="text-lg font-inter font-bold mb-4">Select Withdrawal Amount</h2>
            <div>
              {walletWithdrawConfigData?.data?.data?.withdraw_configs?.map((item, index) => (
                <div key={index} className="flex items-center bg-[#C1E0FF] rounded-2xl overflow-hidden mb-4 ">
                  <div className="flex items-center flex-grow px-4">
                    <div className="bg-[#257059] rounded-full w-12 h-12 flex items-center justify-center mr-3">
                      <svg className='fill-current w-5 h-5' viewBox="0 0 35 33" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M35 14.925C35 12.2232 28.392 10.6398 20.9709 10.2643V6.95732H31.3376V0H3.66178V6.95732H14.0291V10.2643C6.60866 10.6399 0 12.2239 0 14.925C0 17.6255 6.60802 19.2088 14.0291 19.5844V33H20.9715V19.5851C28.392 19.2095 35 17.6255 35 14.925L35 14.925ZM20.9709 18.4274V18.4133C19.8369 18.4827 18.6567 18.5059 17.4997 18.5059C16.3426 18.5059 15.1625 18.4827 14.0285 18.4133V18.4274C5.97463 18.0197 1.15642 16.2447 1.15642 14.925C1.15642 13.6059 5.97398 11.8296 14.0285 11.4219V17.2537C15.1394 17.3232 16.2964 17.3463 17.4997 17.3463C18.7261 17.3463 19.8831 17.3232 20.9709 17.2537V11.4219C29.0247 11.8296 33.8429 13.6059 33.8429 14.925C33.8429 16.2447 29.0254 18.0197 20.9709 18.4274V18.4274Z" fill="white" />
                      </svg>
                    </div>
                    <span className="text-lg font-semibold">{item?.balance ?? 'N/A'} {item?.symbol?.toUpperCase() ?? 'N/A'}</span>
                  </div>
                  <button onClick={() => handleWithdrawAmount(item?.id)} className="bg-[#1388FF] text-white px-6 py-5 font-semibold">
                    withdraw
                  </button>
                </div>
              ))}
            </div>
          </div>
        </>
      )}



      {/* Bottom sheet */}
      {isDropdownOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-end justify-center">
          <div className="bg-white w-full rounded-t-2xl p-4 animate-slide-up">
            <h2 className="text-lg font-semibold mb-4">Select network</h2>
            {walletWithdrawConfigData?.data?.data?.Networks.map((item) => (
              <button
                key={item?.chain_nets?.id}
                onClick={() => handleNetworkSelect({ name: item?.chain_nets?.name, chainnet: item?.chain_nets?.chain_net, address: item?.address })}
                className="w-full text-left p-3 hover:bg-gray-100 transition-colors"
              >
                {item?.chain_nets?.name}
              </button>
            ))}
          </div>
        </div>
      )}

      <ModalLoader isLoading={useBindWalletAddressMutation?.isPending || useWalletWithdrawAmountMutation?.isPending} />
    </div>
  );
};

export default Withdraw;