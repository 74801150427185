
import { fetcher } from "../utils/fetcher";

class WalletService {
  queryKeys = {
    walletInfo: 'walletInfo',
    getWithdrawalRecords: 'getWithdrawalRecords',
    walletWithdrawConfig : 'walletWithdrawConfig',
    bindWalletAddress : 'bindWalletAddress',
    walletWithdrawAmount : 'walletWithdrawAmount',
    getTeamInfo : 'getTeamInfo',
    getTeamRewardsList : 'getTeamRewardsList',
    teamRewardReceive : 'teamRewardReceive'
  };

  walletInfo = async (data) => {
    // console.log({ data });
    const webApp = window?.Telegram?.WebApp
    const payload = {
      platform: webApp?.platform,
      version: webApp?.version,
      ts: Date.now(),
      token: data?.token,
    }
    return fetcher({
      url: '/wallet/info',
      method: 'POST',
      data: payload,
    });
  }

  walletWithdrawConfig = async (data) => {
    // console.log({ data });
    const webApp = window?.Telegram?.WebApp
    const payload = {
      platform: webApp?.platform,
      version: webApp?.version,
      ts: Date.now(),
      channel: 'tt',
      device_no: webApp?.BiometricManager.deviceId,
      token: data?.token,
      symbol: 'USDT'
    }
    return fetcher({
      url: '/wallet/withdraw_config',
      method: 'POST',
      data: payload,
    });
  }


  getWithdrawalRecords = async (data) => {
    console.log(data?.pageParam);
    const webApp = window?.Telegram?.WebApp
    const payload = {
      platform: webApp?.platform,
      version: webApp?.version,
      ts: Date.now(),
      token: data?.token,
      symbol : 'USDT',
      page: data?.pageParam,
      size: 10
    }

    return fetcher({
      url: `/wallet/withdraw_logs`,
      method: 'POST',
      data: payload
    });
  }

  bindWalletAddress = async (data) => {

    return fetcher({
      url: `/wallet/bind_address`,
      method: 'POST',
      data
    });
  }

  walletWithdrawAmount = async (data) => {

    return fetcher({
      url: `/wallet/withdraw`,
      method: 'POST',
      data
    });
  }

  getTeamInfo = async (data) => {
    const webApp = window?.Telegram?.WebApp
    const payload = {
      platform: webApp?.platform,
      version: webApp?.version,
      ts: Date.now(),
      channel : 'tt',
      device_no: webApp?.BiometricManager.deviceId,
      token: data?.token,
    }

    return fetcher({
      url: `/team/info`,
      method: 'POST',
      data: payload
    });
  }

  getTeamRewardsList = async (data) => {
    // console.log(data?.pageParam,'rebb list');
    const webApp = window?.Telegram?.WebApp
    const payload = {
      platform: webApp?.platform,
      version: webApp?.version,
      ts: Date.now(),
      channel : 'tt',
      token: data?.token,
      page: data?.pageParam,
      size: 10
    }

    return fetcher({
      url: `/team/records`,
      method: 'POST',
      data: payload
    });
  }

  teamRewardReceive = async (data) => {

    return fetcher({
      url: `/team/receive`,
      method: 'POST',
      data: data
    });
  }
  

 

}



const walletServiceInstance = new WalletService();
export default walletServiceInstance;
